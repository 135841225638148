<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mx-0 mb-2">
      <CCol sm="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ `${$t('label.file')}:`}} 
        </h6>
        <strong class="ml-1">
          {{ `${ListDetails?.FileName ?? ''}`}} 
        </strong>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <CCol col="12">
        <div class="d-flex align-items-center justify-content-end">
          <CButton
            color="excel"
            size="sm"
            @click="onBtnExport(true)"
          >
            <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
        </div>
      </CCol>
      <CCol col="12" lg="6">
        <div class="form-group form-row mb-0" rol="group">
          <label class="col-form-label-sm col-sm-12 col-lg-auto mb-0 text-right">
            {{$t('label.openErrors')}}
          </label>
          <div class="col-sm-12 col-lg-auto mb-2">
            <div class="d-flex justify-content-end">
              <CSwitch
                color="watch"
                variant="3d"
                type="checkbox"
                :checked.sync="FgError"
                @update:checked="FgError=$event"
              />
            </div>
          </div>
        </div>
      </CCol>
      <CCol col="12" lg="12">
        <dataTableExtended
          class="align-center-row-datatable"
          sorter
          column-filter
          :items="formatedItems"
          :fields="fields"
          :items-per-page="5"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          @row-clicked="RowClicked"
          @filtered-items-change="filteredList=$event"
          pagination
        >
          <template #FgError="{item}">
            <td class="text-center">
              <div class="animation-item" v-if="item.FgError">
                <div class="text-danger"><CIcon name="exclamationSquare" style="width: 40px; height: 24px;"/></div>
              </div>
              <div class="animation-item" v-else>
                <div class="text-success"><CIcon name="checkSquare" style="width: 40px; height: 24px;"/></div>
              </div>
            </td>
          </template>
          <template #GeneralInfo="{item}">
            <td class="text-center">
              {{ item.GeneralInfo ? (item.GeneralInfo.length > 50 ? RemoveSpecialCharacters(item.GeneralInfo, 50) : item.GeneralInfo) : '', }}
            </td>
          </template>
          <template #Observation="{item}">
            <td class="text-center">
              {{ item.Observation ? (item.Observation.length > 50 ? RemoveSpecialCharacters(item.Observation, 50) : item.Observation) : '', }}
            </td>
          </template>
          <template #DescriptionOfGoods="{item}">
            <td class="text-center">
              {{ item.DescriptionOfGoods ? (item.DescriptionOfGoods.length > 50 ? RemoveSpecialCharacters(item.DescriptionOfGoods, 50) : item.DescriptionOfGoods) : '', }}
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ModalError
      :modal="ModalError"
      :File="FileError"
      :isDetails="true"
      @Close="(ModalError=false, FileError={})"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import YardManagement from '@/_mixins/yardManagement';
import ModalError from '../modal-error';

//DATA-------------------------
function data() {
  return {
    ModalError: false,
    Loading: false,
    FgError: false,
    filteredList: [],
    ListDetails: {},
    FileError: {},
  }
}

async function getBookingFileCargo() {
  this.Loading = true;
  await this.$http.get('BookingFileCargo-by-Id', { StowagePlanningFileId: this.StowagePlanningFileId })
    .then(response => {
      this.ListDetails = response?.data?.data[0] ?? {};
    })
    .catch( err => {
      this.$notify({
        group: 'yard',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function RowClicked(item) {
  if(item.FgError){
    this.FileError = item;
    this.ModalError = true;
  }
}

async function onBtnExport(Excel) {
  this.Loading = true;
  if (this.filteredList && this.filteredList.length != 0) {
    let title = this.ListDetails?.FileName ?? '';
    let dataList = [];
    let excelHeader = [
      { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true }
    ]
    await this.fields.forEach((e, index) => {
      if (index > 1) {
        excelHeader.push({ text: e.label, value: e.key, aux: "", json: "" })
      }
    });
    excelHeader.push({ text: this.$t('label.errors'), value: 'Errors', aux: "", json: "" })
    dataList = this.filteredList.map(item => {
      return {
        ...item,
        Weight: item.Weight ? NumberFormater.setNum(item.Weight) : 0,
        Volumen: item.Volumen ? NumberFormater.setNum(item.Volumen) : 0,
        Quantity: item.Volumen ? NumberFormater.setNum(item.Quantity) : 0,
      }
    });
    await this.excel([excelHeader], [dataList], title, '', title)
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }else{
    this.$notify({
      group: "container",
      title: "¡Error!",
      text:  this.$t('label.noRecordsAvailbleReport'),
      type: "error",
    });
  }
  this.Loading = false;
}

function RemoveSpecialCharacters(Value, limit) {
  if (Value) {
    return trimRemoveSpecialCharacters(Value, limit)
  }else{
    return '';
  }
}

function Reset() {
  this.filteredList = [];
  this.ListDetails = {};
  this.FileError = {};
  this.FgError = false;
  this.$emit('Close');
}

//computed
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = [];
  if (this.ListDetails?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST) {
    List = this.ListDetails?.CargoJson ?? [];
    if (this.FgError) {
      List = List.filter(item => item.FgError);
    }
    return List.map((item) => {
      return {
        ...item,
        Nro: Number.parseInt(item.Nro),
        ContainerCode: item.ContainerCode ?? '',
        BlNro: item.BlNo ?? '',
        TpCargoCode: item.TpCargoCode ?? '',
        Consignee: item.Consignee ?? '',
        IsoCode: item.IsoCode ?? '',
        Status: item.Status ?? '',
        Seals: item.Seals ?? '',
        ShippingLineCode: item.ShippingLineCode ?? '',
        PortActivityName: item.PortActivityName ?? '',
        Condition: item.Condition ?? '',
        ImdgCode: item.ImdgCode ?? '',
        LoadPort: item.LoadPort ?? '',
        DischargePort: item.DischargePort ?? '',
        Weight: NumberFormater.formatNumber(item.Weigth, 2),
        DescriptionOfGoods: item.DescriptionOfGoods ?? '',
        ErrorJson: item.JsonError ?? [],
        Errors: item.JsonError.map((Error) => {
          return `${this.$t("label.column")} ${Error.ColumnName}: ${Error['ErrorMessage'+_lang]??''}`;
        }).join(","),
        _style: item.FgError ? 'cursor: pointer;' : '',
      };
    })
  }else{
    return [];
  }
}

function fields(){
  if (this.ListDetails?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST) {
    return [
      { key: 'FgError', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
      { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"text-center", _style:'min-width:205px;'},
      { key: 'TpCargoCode', label: this.$t('label.type'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'BlNro', label: this.$t('label.booking'),_classes:"text-center", _style:'min-width:180px;'},
      { key: 'Consignee', label: this.$t('label.exporter'),_classes:"text-center", _style:'min-width:200px;'},
      { key: 'Status', label: this.$t('label.status'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'Seals', label: this.$t('label.Seal')+'S',_classes:'text-center', _style:'min-width:200px;'},
      { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'PortActivityName', label: this.$t('label.portActivity'),_classes:'text-center', _style:'min-width:190px;'},
      { key: 'Condition', label: this.$t('label.condition'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'ImdgCode', label: 'IMDG',_classes:'text-center', _style:'min-width:140px;'},
      { key: 'LoadPort', label: 'POL',_classes:'text-center', _style:'min-width:150px;'},
      { key: 'DischargePort', label: 'POD',_classes:'text-center', _style:'min-width:150px;'},
      { key: 'Weight', label: this.$t('label.weight')+' (KGM)',_classes:'text-center', _style:'min-width:140px;'},
      { key: 'DescriptionOfGoods', label: this.$t('label.DescriptionOfGoods'),_classes:'text-center', _style:'min-width:250px;'},
    ]
  }else {
    return []
  }
 }

export default {
  name: 'file-details',
  components: {
    ModalError,
  },
  data,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    StowagePlanningFileId: {
      type: String,
      default: () => '',
    }
  },
  mixins: [GeneralMixin, YardManagement],
  methods: {
    getBookingFileCargo,
    RowClicked,
    onBtnExport,
    RemoveSpecialCharacters,
    Reset,
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
      
    })
  },
  watch: {
    active: function (NewVal) {
      if (NewVal) {
        this.getBookingFileCargo();
      }else{
        this.Reset();
      }
    }
  }
};
</script>