<template>
  <div class="mt-2">
    <CCollapse :show="!ShowDetails">
      <CRow class="m-0">
        <CCol sm="12" lg="11" class="px-0">
          <CRow class="m-0">
            <CCol sm="12" lg="3" class="px-0">
              <CSelect
                :placeholder="$t('label.select')"
                addLabelClasses="text-right required"
                :label="$t('label.type')"
                :horizontal="{label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                :options="DocumentTypeOptions"
                v-model="$v.DocumentType.$model"
                :is-valid="hasError($v.DocumentType)"
                :invalid-feedback="errorMessage($v.DocumentType)"
                @change="AcceptedFiles($event.target.value)"
              >
              </CSelect>
            </CCol>
            <CCol v-if="validateBookingContainerList(DocumentType)" sm="12" lg="4" class="px-0">
              <CSelect
                :placeholder="$t('label.select')"
                addLabelClasses="text-right required"
                :label="$t('label.packaging')"
                :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                :options="PackagingOptions"
                v-model="$v.BlCargoMasterDetailId.$model"
                :is-valid="hasError($v.BlCargoMasterDetailId)"
                :invalid-feedback="errorMessage($v.BlCargoMasterDetailId)"
              >
              </CSelect>
            </CCol>
            <CCol sm="12" lg="5" class="px-0">
              <CInput
                :label="$t('label.description')"
                :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-8'}"
                v-model.trim="$v.DocumentDescription.$model"
                :invalid-feedback="errorMessage($v.DocumentDescription)"
                :is-valid="hasError($v.DocumentDescription)"
                addLabelClasses="text-right required"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" lg="1" class="d-flex align-items-start justify-content-end px-0">
          <CButton 
            color="add" 
            square 
            size="sm"
            :disabled="isSubmit"
            @click="Submit"
          >
            <CIcon name="checkAlt"/>
          </CButton>
        </CCol>
      </CRow>
      <vue-dropzone
        ref="myVueDropzone" 
        id="dropzone" 
        :options="dropzoneOptions" 
        :useCustomSlot="true"
        v-on:vdropzone-files-added="SendingEvent"
        v-on:vdropzone-error="DropzoneError"
        class="col-lg-12 mb-3"
      > 
        <div>
          <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
          <div>...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
        </div>
      </vue-dropzone>
      <CCollapse :show="isSubmit" style="width: 100%;">
        <CCol sm="12" class="mb-3">
          <CProgress style="width: 100%;">
            <CProgressBar
              color="success"
              variant="striped"
              animated
              :value="UploadPercent"
            >
              {{ UploadPercent != 100 ? `${UploadPercent}%` : $t('label.readingFile') }}
            </CProgressBar>
          </CProgress>
        </CCol>
      </CCollapse>
      <CCol sm="12" class="px-0">
        <dataTableExtended
          class="align-center-row-datatable"
          hover
          sorter
          :items="formatedItems"
          :fields="fields"
          :items-per-page="5"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          pagination
          column-filter
        >
          <template #Status="{ item }">
            <td class="text-center">
              <div>
                <CBadge :color="getBadge(item.Status)">
                  {{ $t("label." + item.Status) }}
                </CBadge>
              </div>
            </td>
          </template>
          <template #Options="{item}">
            <td class="center-cell">
              <CButton 
                v-if="item.FgActStPlanFile"
                color="wipe"
                size="sm"
                class="mr-1"
                @click="RemoveDocument(item)"
                v-c-tooltip="{
                  content: `${$t('label.removeDocument')}`,
                  placement: 'top-start',
                }"
              >
                <CIcon name="cil-trash"/>
              </CButton>
              <CButton
                color="excel"
                size="sm"
                class="mr-1"
                @click="DownloadArchive(item.Route)"
                v-c-tooltip="{
                content: `${$t('label.download')} ${$t('label.file')}`,
                  placement: 'top-start',
                }"
              >
              <CIcon name="cil-cloud-download"/>
              </CButton>
              <CButton
                color="watch"
                size="sm"
                class="mr-1"
                @click="(StowagePlanningFileId=item.StowagePlanningFileId, ShowDetails=true)"
                v-c-tooltip="{
                  content: $t('label.details'),
                  placement: 'top-start'
                }"
              >
              <CIcon name="cil-list-rich" />
              </CButton>
              <CButton 
                color="light-watch" 
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: $t('label.openErrors'),
                  placement: 'top-start'
                }" 
                @click="(FileError=item, ModalError=true)"
              >
                <CIcon name="cil-exclamation-circle" />
              </CButton>
              <CButton 
                v-if="!item.FgTransferred&&item.FgActStPlanFile&&item.Error==0&&item.Lines!=0"
                color="add"
                size="sm"
                @click="TransferFile(item)"
                v-c-tooltip="{
                  content: `${$t('label.transfer')}`,
                  placement: 'top-start',
                }"
              >
                <CIcon name="cil-share"/>
              </CButton>
              <CButton 
                v-else-if="item.FgTransferred&&item.FgActStPlanFile&&item.Error==0&&item.Lines!=0"
                color="plan"
                size="sm"
                @click="ReverseDocument(item)"
                v-c-tooltip="{
                  content: `${$t('label.reverse')}`,
                  placement: 'top-start',
                }"
              >
                <CIcon name="cil-replay"/>
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CCollapse>
    <CCollapse :show="ShowDetails">
      <FileDetails
        :active="ShowDetails"
        :StowagePlanningFileId="StowagePlanningFileId"
        @Close="(ShowDetails=false, StowagePlanningFileId='')"
      />
    </CCollapse>
    <ModalAlert
      :modal="ModalAlert"
      :File="FileXlsx"
      @Close="(ModalAlert=false, FileXlsx={}, getBookingFileList())"
    />
    <ModalError
      :modal="ModalError"
      :File="FileError"
      @Close="(ModalError=false, FileError={})"
    />
  </div>
</template>
<script>
import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
import General from "@/_mixins/general";
import MassiveLoadingValidations from "@/_validations/bl-query/massiveLoading-BookingValidations";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ModalAlert from './modal-alert'
import FileDetails from './detail/index'
import ModalError from './modal-error'

let user = JSON.parse(localStorage.getItem('user'));

//data
function data() {
  return {
    ShowDetails: false,
    ModalAlert: false,
    ModalError: false,
    isSubmit: false,
    BlCargoMasterDetailId: '',
    DocumentType: '',
    DocumentTypeList: [],
    DocumentDescription: '',
    FileList: [],
    FileXlsx: {},
    FileError: {},
    StowagePlanningFileId: '',
    dropzoneOptions: {
      url:`${process.env.VUE_APP_API_BASE}files/`,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictRemoveFile: `${this.$t('label.delete')}`,
      dictCancelUpload: `${this.$t('label.cancelUpload')}`,
      maxfilesexceeded (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
      acceptedFiles: '.xlsx',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${user.token}`
      }
    },
    UploadPercent: 0,
  };
}

//methods
async function getBookingTpStowageFile() {
  await this.$http.get("BookingTpStowageFile-list")
    .then((response) => {
      this.DocumentTypeList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

async function getBookingFileList(loading = false) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("BookingFileList-by-BlCargoId", { BlCargoId: this.Bl?.BlCargoId ?? '' })
    .then((response) => {
      this.FileList = response?.data?.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = loading;
    });
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    let File = this.$refs.myVueDropzone.getAcceptedFiles()[0];
    this.$v.$touch();
    if (!File) {
      throw this.$t('label.documentQuestion');
    }else if (this.$v.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let formData = new FormData();
    formData.append('files', File);
    formData.append('BlCargoId', this.Bl?.BlCargoId ?? '');
    formData.append('BlCargoMasterDetailId', this.BlCargoMasterDetailId);
    formData.append('TpStowageFileId', this.DocumentType);
    formData.append('FileDescription', this.DocumentDescription);

    let Route = 'BookingContainerFile';
    this.$http.post(Route, formData, {
      baseURL: this.$store.getters['connection/getApiBase'],
      onUploadProgress: function( progressEvent ) {
        this.UploadPercent = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
      }.bind(this)
    })
    .then((response) => {
      this.$refs.myVueDropzone.removeAllFiles();
      this.DocumentDescription = '';
      this.BlCargoMasterDetailId = '';
      let Json = response?.data?.data[0]?.Json ?? {};
      if (Json?.FgSave != 0) {
        this.FileXlsx = Json.ResponseJson[0] ?? {};
        this.ModalAlert = true;
      }else {
        this.$swal.fire({
          icon: "warning",
          text: Json?.Response ? Json.Response.toUpperCase() : '',
          cancelButtonColor: '#E1373F',
          cancelButtonText: this.$t('button.cancel'),
          showConfirmButton: false,
          showCancelButton: true,

        })
      }
      this.$v.$reset();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.isSubmit = false;
      this.UploadPercent = 0;
      this.$store.state.yardManagement.loading = false;
    });
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.$notify({
      group: "container",
      title: "¡Error!",
      text: error,
      type: "error",
    });
  }
}

function RemoveDocument(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.deleteFile')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      let BookingContainerFileJson = {
        StowagePlanningFileId: item.StowagePlanningFileId,
      }
      this.$http.put('BookingContainerFileDeactivate', BookingContainerFileJson, { root: 'BookingContainerFileJson'})
        .then(async (response) => {
          await this.getBookingFileList(true);
          await this.$emit('Update', this.Bl);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        })
        .catch((err) => {
          this.$store.state.yardManagement.loading = false;
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
        })
    }
  });
}

async function TransferFile(item) {
  this.$store.state.yardManagement.loading = true;
  let BookingContainerFileJson = {
    StowagePlanningFileId: item.StowagePlanningFileId,
  }
  await this.$http.post("BookingContainerFileTransfer", BookingContainerFileJson, { root: 'BookingContainerFileJson'})
    .then(async() => {
      await this.getBookingFileList(true);
      await this.$emit('Update', this.Bl);
      this.$notify({
        group: "container",
        title: '¡'+this.$t('label.success')+'!',
        text: this.$t('label.TransferredFile'),
        type: "success",
      });
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function ReverseDocument(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.reverseFile')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      let BookingContainerFileJson = {
        StowagePlanningFileId: item.StowagePlanningFileId,
      }
      this.$http.post('BookingContainerFileTransferReverse', BookingContainerFileJson, { root: 'BookingContainerFileJson'})
        .then(async (response) => {
          await this.getBookingFileList(true);
          await this.$emit('Update', this.Bl);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
        })
        .catch((err) => {
          this.$store.state.yardManagement.loading = false;
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: err,
            type: "error",
          });
        })
    }
  });
}

function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
  let Msg = this.DocumentType ? this.$t('label.docTypeInvalid') : this.$t('label.mustSelectDocType');
  this.$swal.fire({
    icon: 'error',
    title: '',
    text: Msg,
  })
}

function SendingEvent(file) {
  if (!this.DocumentType) {
    this.$swal.fire({
      icon: 'error',
      title: '',
      text: this.$t('label.mustSelectDocType'),
    })
    setTimeout(() => {
      this.$refs.myVueDropzone.removeAllFiles();
    }, 10);
  }
}

function InstructiveRoute(Id) {
  if (Id == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST) {
    return 'download/IntructivoManifiestoBl.pdf';
  }else {
    return '';
  }
}

function FileAlert() {
  this.$swal.fire({
    text: `${this.$t('label.FileAlert')}`,
    icon: "warning",
    confirmButtonColor: "#42aa91",
    confirmButtonText: "OK"
  }).then((result) => {
    if (result.isConfirmed) {
      if (this.DocumentType == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST) {
        window.open(`${process.env.VUE_APP_FILES_URL}download/BillOfLadingManifest.xlsx`);
      }
    }
  })
}

function AcceptedFiles(Id) {
  this.$store.state.yardManagement.loading = true;
  this.DocumentType = Id ?? '';
  if (this.DocumentType) {
    let DocumentTypeData = this.DocumentTypeList.find(item => item.TpStowageFileId == this.DocumentType);
    this.dropzoneOptions.acceptedFiles = DocumentTypeData ? DocumentTypeData.Format : '.xlsx';
  }else {
    this.dropzoneOptions.acceptedFiles = '.xlsx';
  }
  this.$refs.myVueDropzone.removeAllFiles();
  setTimeout(() => {
    this.$store.state.yardManagement.loading = false;
  }, 10);
}

function validateBookingContainerList(id) {
  return id == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST;
}

function DownloadArchive(Route) {
  return window.open((`${process.env.VUE_APP_FILES_URL}${Route?.replace(/Public/i, '')}`), '_blank');
}

function Reset() {
  this.$refs.myVueDropzone.removeAllFiles();
  this.ShowDetails = false;
  this.DocumentType = '';
  this.DocumentDescription = '';
  this.BlCargoMasterDetailId = '';
  this.DocumentTypeList = [];
  this.FileList = [];
  this.FileXlsx = {};
  this.FileError = {};
  this.StowagePlanningFileId = '';
  this.UploadPercent = 0;
  this.$v.$reset();
}

//computed
function DocumentTypeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.DocumentTypeList.map(function(e){
    chart.push({
      value: e.TpStowageFileId, 
      label: e['TpStowageFileName'+_lang] ?? '',
    })    
  })
  return chart;
}

function PackagingOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  this.Bl?.DetailJson?.map((e) => {
    if (e.PackagingId == process.env.VUE_APP_PACKAGING_ID_CONTAINER) {
      chart.push({
        value: e.BlCargoMasterDetailId, 
        label: `${e['PackagingName'+_lang]} ${e.TpCargoDetailCode ? `(${e.TpCargoDetailCode})` : ''}`,
      })
    } 
  })
  return chart;
}

function fields(){ 
  return [
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 225px',  sorter: false,  filter: false, _classes:'text-center' },
    { key: 'Nro', label: '#',_style: 'min-width:45px;',  _classes:'text-center', filter: false},
    { key: 'TpStowageFileName', label: this.$t('label.type'), _style: 'min-width: 180px;', _classes:'text-center'},
    { key: 'FileName', label: this.$t('label.filename'), _style: 'min-width: 180px;', _classes:'text-center'},
    { key: 'FileDescription', label: this.$t('label.description'), _style: 'min-width: 250px;', _classes:'text-center'},
    { key: 'Lines', label: this.$t('label.rows'), _style: 'min-width: 130px;', _classes:'text-center'},
    { key: 'Error', label: this.$t('label.wrongRows'), _style: 'min-width: 180px;', _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'min-width: 130px;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'min-width: 130px;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'), _style: 'min-width: 130px;', _classes:'text-center'},
  ]
}

function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.FileList.map((item) => {
    return {
      ...item,
      Nro: Number.parseInt(item.Nro),
      TpStowageFileName: item['TpStowageFileName'+_lang] ?? '',
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
      _classes: !item.FgActStPlanFile ? 'table-danger' : item.Error != 0 ? 'Error-Row' : 'table-success',
    }
  })
}

export default {
  name: "electronic-data-index",
  mixins: [General],
  data,
  props:{
    Bl: {
      type: Object,
      Required: true,
      default: undefined,
    },
    tab: Boolean,
  },
  components: {
    vueDropzone: vue2Dropzone,
    ModalAlert,
    FileDetails,
    ModalError,
  },
  validations: MassiveLoadingValidations,
  methods: {
    getBookingTpStowageFile,
    getBookingFileList,
    Submit,
    RemoveDocument,
    TransferFile,
    ReverseDocument,
    DropzoneError,
    SendingEvent,
    InstructiveRoute,
    FileAlert,
    AcceptedFiles,
    validateBookingContainerList,
    DownloadArchive,
    Reset,
  },
  computed: {
    DocumentTypeOptions,
    PackagingOptions,
    fields,
    formatedItems,
  },
  watch: {
    tab: async function (newVal) {
      if (newVal) {
        this.$store.state.yardManagement.loading = true;
        await this.getBookingTpStowageFile();
        await this.getBookingFileList();
      }else{
        this.Reset();
      }
    },
  },
};
</script>